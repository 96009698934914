<template>
    <base-modal :title="announcement.subject" :footer="false" mode="small">
        <div id="announcement-info" style="margin-top: 5px" >
            <div>
                <sub style="color: white"><em>{{ formatDate(announcement.created_at) }}</em></sub>
                <p v-slimscroll="{height: 200}">{{ announcement.description }}</p>
            </div>
        </div>
        <div></div>
        <div></div>
    </base-modal>
</template>

<script>
import {formatAnnouncementDate} from "../../../utility/datetime";
export default {
    name: "AnnouncementInfo",
    props: {
        announcement: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {}
    },
    computed: {},
    methods: {
        formatDate: function (date) {
            return formatAnnouncementDate(date);
        }
    }
}
</script>

<style scoped lang="scss">
#features {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#features td, #features th {
  border: 1px solid #ddd;
  padding: 8px;
}

#features tr:nth-child(even) {
  background-color: #f2f2f2;
}

#features th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
}

.voteIcon:hover {
  color: green !important;
}

.howItWorksHeader {
  font-size: 19px;
  color: #d5ad35;
  padding-top: 3px;
}

a:link {
  text-decoration: none;
  color: #fefefe;
}

a:visited {
  text-decoration: none;
  color: #fefefe;
}

a:hover {
  text-decoration: none;
  color: #fefefe;
}

a:active {
  text-decoration: none;
  color: #fefefe;
}

p {
  font-size: 12px;

}

label {
  padding-top: 10px;
}


/* Feeback navigation */

#feedbackNav {
  max-width: auto;
  height: 60px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: grid;
  grid-template-columns: 3fr 1.2fr 1.2fr 1.2fr 3fr;
  grid-template-rows: 1fr;
  grid-gap: 5px;

  color: #fefefe;
  text-align: center;
  font-size: 14px;
}


/* non active button   */
.dashboadFeedbackButton.non-active, .comingUpFeedbackButton.non-active, .releasesFeedbackButton.non-active {
  min-width: 120px;
  background: rgb(27, 24, 24);
  margin-top: 10px;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  color: #fefefe;
  border: 0 solid #fefefe;
  cursor: pointer;
}

/* active button   */
.dashboadFeedbackButton.active, .comingUpFeedbackButton.active, .releasesFeedbackButton.active {
  min-width: 120px;
  margin-top: 10px;
  background: #253138;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  color: #d5ad35;
  border: 1px solid #fefefe;
  cursor: pointer;

}

.dashboadFeedbackButton a {

  padding-top: 20px;
  color: #d5ad35;
}


.dashboadFeedbackButton:hover {
  background: cornflowerblue;
  color: #fefefe;
}


.dashboadFeedbackButton:active {
  background-color: #253138;

}

/* Coming up button   */
.comingUpFeedbackButton:hover {
  background: cornflowerblue;
}

.comingUpFeedbackButton:active {
  background-color: #253138;
}


/* New releases button   */
.releasesFeedbackButton:hover {
  background: cornflowerblue;
}

.releasesFeedbackButton:active {
  background-color: #253138;
}


/* Main content                    */

.dashboardMain {

  max-width: 100%;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 2fr 8fr;
  grid-template-rows: auto;
  grid-gap: 3px;
  color: #fefefe;
  font-weight: bold;
  font-size: 12px;
  height: 550px;
}

/* Left Column */
.leftSide {
  max-width: 100%;
  min-width: 170px;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-right: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-gap: 5px;
  color: #fefefe;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  height: 250px;

}

/* Submit a request */
.submitRequestSection {
  background: #253138;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 5px;
  padding-bottom: 10px;
  padding-top: 5px;
  padding-left: 20px;
  margin-bottom: 20px;
  max-height: 100px;
  border: solid 1px black;
}

.submitRequestSectionHead {
  background: #253138;
  padding: 0;
  height: auto;


}

/* suggestion button leads to suggestion form  */
.makeSuggestionBtn {
  background: #d5ad35;
  color: #fefefe;
  padding: 10px;
  border: none;
  max-width: 150px;
  cursor: pointer;

}

.makeSuggestionBtn:hover {
  background: #f3ce60;

}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9; /* Sit on top */
  padding-top: 40px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(24, 32, 37); /* Fallback color */
  background-color: rgba(24, 32, 37, 0.9); /* Black w/ opacity */
  overflow: auto;
}

/* Modal Content */
.modal-content {
  background-color: #253138;
  margin: auto;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  width: 60%;
}

/* The Close Button */
.close {
  color: rgba(213, 173, 53, 0.2);
  float: right;
  font-size: 40px;
  font-weight: bold;
  padding-right: 70px;
  padding-bottom: 30px;
}

.close:hover,
.close:focus {
  color: #d5ad35;
  text-decoration: none;
  cursor: pointer;
}

.feedbackFormSection, p {

  color: #98a6ad;
}

// .submitRequestHead, h2 {
//   font-size: 16px;
//   padding-bottom: 2px !important;
//   margin-bottom: 0px !important;

// }

.submitBtnSection {
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  grid-gap: 5px;
  grid-template-rows: 1fr;
  padding-top: 15px;
}

#submitBtn {
  width: auto;
  padding: 1rem 2rem;
  background-color: #d5ad35;
  border: none;
  color: #fefefe;
  font-size: 1.5rem;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;

}


.modalHeadings {
  padding-top: 10px;
  font-size: 17px;
  color: #fefefe;
}

.modalSubHeadings {
  font-size: 12px;
  color: #98a6ad;
  margin-top: 10px;
  margin-bottom: 10px;
}

.modalPara {
  color: #98a6ad;
}

.howItWorksSection {
  background: #253138;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-gap: 5px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  max-height: 100%;
  border: solid 1px black;
}


.howItWorksHead, h2 {
  font-size: 16px;
  color: #fefefe;

}

.howItWorksBtnSection {
  padding-bottom: 10px;

}

/*Learn more button with dropdown section  */


/* The Close Button */
.closeThis {
  color: rgba(213, 173, 53, 0.2);
  float: right;
  font-size: 40px;
  font-weight: bold;
  padding-right: 70px;
  padding-bottom: 30px;
}

.closeThis:hover,
.closeThis:focus {
  color: #d5ad35;
  text-decoration: none;
  cursor: pointer;
}


/* Latest Updates */
.whatsNewSection {
  background: #253138;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 5px;
  padding-left: 20px;
  padding-top: 5px;
  height: auto;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

p .whatsNewSection {

  color: rgb(64, 216, 102);
}

.whatsNewHead {

  padding-left: 0px;
  height: auto

}


/* Right Column */

.rightSide {
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 0px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-gap: 5px;
  color: #fefefe;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  height: auto;
  border: solid 1px black;
}

/* Sugested by others section */
.otherSuggestions {
  background: #253138;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  padding-bottom: 10px;
}

.otherSuggestionsHead {
  display: grid;
  grid-template-columns: 7fr 0fr 0fr 0fr 0fr;
  grid-template-rows: auto;
  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
}

.feedbackVotedForCounter {
  color: rgb(88, 170, 20);
}

.feedbackVotedFor {

  text-align: right;
  padding-right: 3px;
}

.feedbackVotedForFeatureSuggestions {

  padding-left: 3px;
}

/*Filter button shows suggestions that have not been voted on by user yet   */
.suggestionsVotedForeFilterBtn {

  background: #d5ad35;
  color: #fefefe;
  margin-top: 10px;
  padding: 5px;
  text-align: right;
  border: none;

}


.suggestionsVotedForeFilterBtn:hover {

  background: rgb(88, 170, 20);

}

.suggestionTable {

  background: #253138;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px;
  grid-gap: 5px;
  margin-bottom: 10px;
  min-height: 30px;

}

.suggestionTableHead {
  background: rgb(32, 30, 30);
  display: grid;
  grid-template-columns: 3fr 7fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 5px;
  padding-left: 5px;
  margin-left: 15px;;
  margin-right: 15px;
  padding-right: 10px;
  height: 35px;

}

.suggestionTableData, .suggestionTableData2 {
  background: #253138;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat;
  grid-gap: 5px;
  padding-left: 10px;
  margin-left: 5px;
  padding-right: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-top: 10px;
}

.suggestionTableData3, .suggestionTableData4 {
  background: #253138;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat;
  grid-gap: 5px;
  padding-left: 10px;
  margin-left: 5px;
  padding-right: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  min-height: 20px;

}

.hiddenData3 {
  display: none;
  height: auto;
  min-height: 20px;
}

.hiddenData4 {
  display: none;
  height: auto;
  min-height: 20px;
}

.suggestionTableDataRow {
  background: #182025;
  display: grid;
  grid-template-columns: 3fr 7fr 1fr;
  grid-template-rows: 1fr auto;
  grid-gap: 5px;
  max-height: 40px;
  padding-top: 10px;
  font-size: 14px;
}

.suggestionTableDataRow2 {
  display: grid;
  grid-template-columns: 13.3fr;
  grid-template-rows: 1fr auto;
  grid-gap: 5px;
  background: #182025;
}

.suggestionTableDataRowExpanded {
  background: #182025;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: 5px;
  text-align: center;
  padding: 20px;
}

.suggestionExpandedRowInsert {
  background: #253138;
  display: grid;
  grid-template-columns: 10fr;
  grid-template-rows: auto;
  grid-gap: 5px;
  text-align: center;
  padding: 20px;
}

.hidden-content {
  display: none;
}


.detailsExpandSuggestionRow {
  cursor: pointer;
}

.suggestionAchive {
  text-align: left;
  padding-bottom: 20px;
}

.suggestionWorkAround {
  text-align: left;
}

.voteStatusColumn {
  padding-left: 5px;
}

.hiddenVoted {
  display: block;
}

.expandedRowHeading {
  font-weight: bold;
  padding-bottom: 5px;
  color: #d5ad35;
  font-size: 14px;
}


.voteSuggestion {

  background: darkgrey;
  margin-left: 5px;
  margin-top: 5px;

}

.voteSuggestion:hover {
  background: rgb(75, 74, 74);
  margin-left: 5px;
  margin-top: 5px;

}

.voteSection {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  text-align: center;
  padding-left: 15px;


}

.voteSectionText {
  text-align: center;
  padding-top: 10px;
  font-size: 15px;
}

.voteUpBtn {
  width: auto;
  padding: 10px;
  text-align: center;
  background-color: rgb(23, 148, 23);
  color: #fefefe;
  border: none;
  border-radius: 25px;
  max-width: 70px;
  font-weight: bold;
  cursor: pointer;
}

i {
  pointer-events: none;
}


i {
  pointer-events: none;
}

.description {
  font-size: 15px;
  text-align: left;
  color: #fefefe;
  width: auto;

}

.description, p {
  font-size: 13px;
  text-align: left;
  color: #fefefe;
  width: auto;

}


.description, b {
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  color: #d5ad35;
  width: auto;

}

.description, h6 {
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  color: #d5ad35;
  width: auto;
}


@media screen and (max-width: 1200px) {
  /* for screens that are less than or equal to 1200 pixels wide */
  /* suggestion button leads to suggestion form  */
  .makeSuggestionBtn {
    background: #d5ad35;
    color: #fefefe;
    padding: 10px;
    border: none;
    max-width: 150px;
    font-size: 12px;
  }


  /*Learn more button with dropdown section  */
  .howItWorksBtn {
    background: #d5ad35;
    color: #fefefe;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 32px;
    padding-right: 32px;
    border: none;
    max-width: 150px;
    font-size: 12px;
  }
}

@media screen and (max-width: 1000px) {
  /* for screens that are less than or equal to 1000 pixels wide */
  .fullscreen {
    width: 100%;
    grid-row: 3;
    grid-column: 1/13;
  }


  .dashboardMain {

    max-width: 100%;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-gap: 3px;
    color: #fefefe;
    font-weight: bold;
    font-size: 12px;
    height: auto;

  }


  /* Left Column */
  .leftSide {
    max-width: 100%;
    margin-bottom: 40px;
    margin-top: 20px;
    margin-right: 0px;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-gap: 5px;
    color: #fefefe;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    height: 60px;

  }

  .suggestionTableHead {
    background: rgb(32, 30, 30);
    display: grid;
    grid-template-columns: 2.1fr 6fr 2fr 1.2fr;
    grid-template-rows: 1fr;

    padding-left: 5px;
    margin-left: 15px;;
    margin-right: 15px;
    padding-right: 10px;
    height: 35px;
    padding-top: 5px;
    font-size: 11px;

  }


  .suggestionTableDataRow {
    background: #182025;
    display: grid;
    grid-template-columns: 2.1fr 6fr 2fr 1.2fr;
    grid-template-rows: 1fr auto;
    max-height: 35px;
    padding-top: 10px;
    font-size: 11px;
  }

  .suggestionTableDataRow2 {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr auto;
    grid-gap: 5px;
    background: #182025;
  }
}


@media screen and (max-width: 400px) {
  /* for screens that are less than or equal to 400 pixels wide */
  .fullscreen {
    width: 100%;
    grid-row: 3;
    grid-column: 1/13;
  }

  #feedbackNav {
    max-width: 100%;
    height: 60px;
    margin-top: 0px;
    margin-bottom: 0px;
    display: grid;
    grid-template-columns: 1% 30% 30% 31% 1%;
    grid-template-rows: 1fr;
    grid-gap: 5px;

    color: #fefefe;
    text-align: center;
    font-size: 10px;
  }

  /* non active button   */
  .dashboadFeedbackButton.non-active, .comingUpFeedbackButton.non-active, .releasesFeedbackButton.non-active {
    min-width: 70px;
    background: rgb(27, 24, 24);
    margin-top: 10px;
    padding-top: 10px;
    padding-left: 1px;
    padding-right: 1px;
    color: #fefefe;
    cursor: pointer;
  }

  /* active buttons   */
  .dashboadFeedbackButton.active, .comingUpFeedbackButton.active, .releasesFeedbackButton.active {
    min-width: 70px;
    margin-top: 10px;
    background: #253138;
    padding-top: 10px;
    padding-left: 1px;
    padding-right: 1px;
    color: #d5ad35;
    border: 1px solid #fefefe;
    cursor: pointer;

  }


  /*submit request btn */
  .submitRequestSection {
    background: #253138;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 5px;
    padding-bottom: 10px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 20px;
    max-height: 100px;
  }

  /* How it works */
  .howItWorksSection {
    background: #253138;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-gap: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    max-height: 100px;
  }


  .suggestionTableDataRow {
    background: #182025;
    display: grid;
    grid-template-columns: 1.8fr 6fr 2.2fr 1fr;
    grid-template-rows: 1fr auto;
    grid-gap: 5px;
    min-height: 40px;
    padding-top: 10px;
    padding-right: 5px;


  }

  .suggestionTableData, .suggestionTableData2 {
    background: #253138;
    display: grid;
    grid-template-columns: 1fr;
    /*grid-template-rows: repeat;*/
    grid-gap: 5px;
    padding-left: 3px;
    margin-left: 2px;
    padding-right: 3px;
    padding-bottom: 5px;
    margin-right: 2px;
    margin-bottom: 5px;
    padding-top: 10px;
  }


  .suggestionTableHead {
    background: rgb(32, 30, 30);
    display: grid;
    grid-template-columns: 1.8fr 7.5fr 2fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 2px;
    padding-left: 3px;
    margin-left: 5px;
    padding-right: 3px;
    padding-top: 5px;
    margin-right: 5px;
    margin-bottom: 5px;

  }

  .suggestionRowStatusText.voting, .suggestionRowStatusText.planned, .suggestionRowStatusText.development, .suggestionRowStatusText.live {
    text-align: center;

  }


  .fa-plus-square::before {
    font-size: 18px;


  }

  .fa-minus-square::before {
    font-size: 18px;


  }

  .fa-square::before {
    font-size: 16px;
    text-align: center;
    padding-right: 0px;
  }

  .fa-square {
    font-size: 16px;
    padding-right: 0px;
    text-align: center;
    padding-left: 10px;
  }

  i.fa {
    padding-right: 0px;
  }

  p {
    font-size: 10px;
  }

  b {
    font-size: 12px;
  }

  h6 {
    font-size: 12px;

  }

  .voteSectionText {
    font-size: 12px;
  }

  .otherSuggestionsHead {
    display: grid;
    grid-template-columns: 7fr 0fr 0fr 0fr 0fr;
    grid-template-rows: 30px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    height: 30px;
  }

  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9; /* Sit on top */
    padding-top: 0px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    overflow: auto;
  }

  /* Modal Content */
  .modal-content {
    background-color: #253138;
    margin: auto;
    padding-left: 20px;
    padding-bottom: 120px;
    padding-right: 20px;
    border: 1px solid #888;
    width: 100%;
    height: 100%;
  }


  /* The Close Button */
  .close {
    color: #d5ad35;
    float: right;
    font-size: 20px;
    font-weight: bold;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 0px;
  }

  /* The Close Button */
  .closeThis {
    color: #d5ad35;
    float: right;
    font-size: 20px;
    font-weight: bold;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .priorityHeading {
    padding-left: 5px;
    padding-right: 5px;
    height: 30px;

  }

  #priorityData {
    padding-left: 5px;
    padding-right: 5px;
  }


}


</style>
